import React, { useState } from 'react'
import css from '../OrderPanel.module.css'
const OrderPanelColorInput = ({ colors, selectedColor, handleColorChange }) => {
    return (
        <div className={css.colorRadio}>
            {colors.map((color, index) => (
                <React.Fragment key={index}>
                    <input
                        type="radio"
                        name="color"
                        checked={selectedColor === color.name}
                        onChange={() => handleColorChange(color.name)}
                        style={{
                            width: '25px',
                            height: '25px',
                            appearance: 'none',
                            WebkitAppearance: 'none',
                            borderRadius: '50%',
                            marginRight: '8px',
                            cursor: 'pointer',
                            backgroundColor: color.colorCode,
                            border: color.colorCode === 'white'
                                ? (selectedColor === color.name ? 'none' : '1px solid #d9d9d9')
                                : `2px solid ${color.colorCode}`,
                            boxShadow: selectedColor === color.name && '0 0 0 2px white, 0 0 0 3px black',
                            outline: 'none',
                        }}
                    />
                </React.Fragment>
            ))}
        </div>
    )
}

export default OrderPanelColorInput
