import React, { useState, useEffect } from 'react'
import css from '../OrderPanel.module.css'

const OrderPanelSize = ({ selectedColor, colors, setSelectedSize, selectedSize }) => {
    const selectColor = colors.find(color => color?.name === selectedColor)

    useEffect(() => {
        if (selectColor?.sizes.length) {
            const firstAvailableSize = selectColor.sizes.find(size => size.available)
            if (firstAvailableSize) {
                setSelectedSize(firstAvailableSize.size)
            }
        }
    }, [selectColor])

    return (
        <div className={css.size}>
            {selectColor?.sizes?.map((size) => {
                const sizeClass = size.available ? '' : css.unavailable;
                const isSelected = size.size === selectedSize ? css.selected : '';

                return (
                    <div
                        key={size.size}
                        className={`${css.sizeInput} ${sizeClass} ${isSelected}`}
                        onClick={() => size.available && setSelectedSize(size.size)}
                    >
                        {size.size}
                    </div>
                )
            })}
        </div>
    )
}

export default OrderPanelSize
