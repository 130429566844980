import React, { Component } from 'react';
import { string, shape, number, object, arrayOf } from 'prop-types';
import MultiTouch from 'mapbox-gl-multitouch';
import uniqueId from 'lodash/uniqueId';
import { circlePolyline } from '../../util/maps';
import locationPin from '../../assets/locationPin.svg';

const mapMarker = (lngLat, label) => {
  const el = document.createElement('div');
  el.className = 'custom-marker';

  const img = document.createElement('img');
  img.src = locationPin;
  img.style.width = '30px';
  img.style.height = '40px';

  const span = document.createElement('span');
  span.textContent = label;
  span.style.color = '#000';

  el.appendChild(img);
  el.appendChild(span);

  return new window.mapboxgl.Marker(el).setLngLat(lngLat);
};

const circleLayer = (center, mapsConfig, layerId) => {
  const path = circlePolyline(center, mapsConfig.fuzzy.offset).map(([lat, lng]) => [lng, lat]);
  return {
    id: layerId,
    type: 'fill',
    source: {
      type: 'geojson',
      data: {
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: [path],
        },
      },
    },
    paint: {
      'fill-color': mapsConfig.fuzzy.circleColor,
      'fill-opacity': 0.2,
    },
  };
};

const generateFuzzyLayerId = () => {
  return uniqueId('fuzzy_layer_');
};

class LandingPageLocationsMap extends Component {
  constructor(props) {
    super(props);

    this.mapContainer = null;
    this.map = null;
    this.markers = [];
    this.fuzzyLayerId = generateFuzzyLayerId();

    this.updateFuzzyCirclelayer = this.updateFuzzyCirclelayer.bind(this);
  }

  componentDidMount() {
    const { center, zoom, mapsConfig, locations } = this.props;
    const position = [center.lng, center.lat];

    this.map = new window.mapboxgl.Map({
      container: this.mapContainer,
      style: 'mapbox://styles/mapbox/streets-v10',
      center: position,
      zoom,
      scrollZoom: true,
      dragPan: true,
    });
    this.map.addControl(new window.mapboxgl.NavigationControl({ showCompass: false }), 'top-left');

    // Note: Adding MultiTouch causes issues with the ability to zoom in and out with touchpad,
    //  so this feature was removed (for now).
    // this.map.addControl(new MultiTouch());

    if (mapsConfig.fuzzy.enabled) {
      this.map.on('load', () => {
        this.map.addLayer(circleLayer(center, mapsConfig, this.fuzzyLayerId));
      });
    } else {
      this.addMarkers(locations);
    }
  }

  componentWillUnmount() {
    this.removeMarkers();
    if (this.map) {
      this.map.remove();
      this.map = null;
    }
  }

  componentDidUpdate(prevProps) {
    if (!this.map) {
      return;
    }

    const { center, zoom, mapsConfig, locations } = this.props;
    const { lat, lng } = center;
    const position = [lng, lat];

    if (zoom !== prevProps.zoom) {
      this.map.setZoom(this.props.zoom);
    }

    const centerChanged = lat !== prevProps.center.lat || lng !== prevProps.center.lng;

    if (centerChanged) {
      this.map.setCenter(position);
    }

    if (mapsConfig.fuzzy.enabled && centerChanged) {
      if (this.map.loaded()) {
        this.updateFuzzyCirclelayer();
      } else {
        this.map.on('load', this.updateFuzzyCirclelayer);
      }
    }

    if (locations !== prevProps.locations) {
      this.removeMarkers();
      this.addMarkers(locations);
    }
  }

  addMarkers(locations) {
    locations.forEach(location => {
      const marker = mapMarker([location.lng, location.lat], location.label);
      marker.addTo(this.map);
      this.markers.push(marker);
    });
  }

  removeMarkers() {
    this.markers.forEach(marker => marker.remove());
    this.markers = [];
  }

  updateFuzzyCirclelayer() {
    if (!this.map) {
      return;
    }
    const { center, mapsConfig } = this.props;
    const { lat, lng } = center;
    const position = [lng, lat];

    this.map.removeLayer(this.fuzzyLayerId);

    this.fuzzyLayerId = generateFuzzyLayerId();
    this.map.addLayer(circleLayer(center, mapsConfig, this.fuzzyLayerId));

    this.map.setCenter(position);
  }

  render() {
    const { containerClassName, mapClassName } = this.props;
    return (
      <div className={containerClassName}>
        <div
          className={mapClassName}
          ref={el => (this.mapContainer = el)}
          style={{ width: '100%', height: '500px' }}
        />
      </div>
    );
  }
}

LandingPageLocationsMap.defaultProps = {
  address: '',
  center: null,
  locations: [],
};

LandingPageLocationsMap.propTypes = {
  address: string, // not used
  center: shape({
    lat: number.isRequired,
    lng: number.isRequired,
  }).isRequired,
  zoom: number.isRequired,
  mapsConfig: object.isRequired,
  locations: arrayOf(
    shape({
      lat: number.isRequired,
      lng: number.isRequired,
      label: string.isRequired,
    })
  ),
};

export default LandingPageLocationsMap;
