import React from 'react'
import ReactDom from 'react-dom'

import css from './CartModal.module.css';

import xIcon from '../../assets/xIcon.svg'

const modalStyles = {
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: '#FFF',
  // padding: '40px 80px',
  zIndex: 1000,
  boxSizing: 'border-box',
  border: "1px solid black",
  borderRadius: "6px"
}

const overlayStyles = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, .7)',
  zIndex: 1000
}

const buttonStyles = {
  border: "none",
  backgroundColor: "transparent",
  cursor: "pointer",
  fontSize: "20px",
  padding: "0"
}

export default function CartModal({ open, children, onClose }) {
  if (!open) return null

  return ReactDom.createPortal(
    <>
      <div style={overlayStyles} />
      <div style={modalStyles} className={css.cartModal}>
        <button style={buttonStyles} onClick={onClose} className={css.closeButton}>
          <img src={xIcon} alt="X" />
        </button>
        <div className={css.modalBody}>
          {children}
        </div>
      </div>
    </>,
    document.getElementById('portal-root')
  )
}