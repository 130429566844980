import React, {useState} from 'react';
import css from './RemoveIncrementCartButton.module.css';
import trashIcon from '../../assets/trashIcon.svg';

const RemoveIncrementCartButton = props => {
  const {
    listing,
    count: initialCount,
    incrementCart,
    isListingPage,
    buttonLabel,
    cartLabel,
    isBooking = false,
    showProductOrderForm = true,
    isOwnListing = false,
  } = props;

  if (isBooking || !showProductOrderForm) {
    return null;
  }

  const [localCount, setLocalCount] = useState(initialCount);
  const [canClick, setCanClick] = useState(true);

  const enableClick = () => {
    setCanClick(true);
  };

  const rateLimitClick = (callback) => {
    if (canClick) {
      callback();
      setCanClick(false);
      setTimeout(enableClick, 500);
    }
  };

  const increaseCount = () => {
    setLocalCount(localCount + 1);
    incrementCart(1);
  };

  const decreaseCount = () => {
    setLocalCount(localCount - 1);
    incrementCart(-1);
  };

  const currentStock = listing?.currentStock?.attributes.quantity;
  const isMaxItems = currentStock <= localCount;

  return (
    <div className={css.container}>
      {localCount === 1 ? (
        <button className={css.smallButton} onClick={() => rateLimitClick(decreaseCount)}
                style={{marginLeft: '8px'}}>
          <img src={trashIcon} alt="Remove from cart" style={{width: '20px'}}/>
        </button>
      ) : (
        <button className={css.smallButton} onClick={() => rateLimitClick(decreaseCount)}
                style={{marginLeft: '8px'}}>
          -
        </button>
      )}
      <span className={css.countContainer}>{localCount}</span>
      <button className={css.smallButton} disabled={isMaxItems}
              onClick={() => rateLimitClick(increaseCount)} style={{marginRight: '8px'}}>
        +
      </button>
    </div>
  );
};

RemoveIncrementCartButton.defaultProps = {
  isListingPage: false,
};

export default RemoveIncrementCartButton;
