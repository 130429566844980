import React, { useState, useEffect } from 'react';
import css from '../AddToCartButton/AddToCartButton.module.css';

const AddToCartButton = props => {
  const {
    listing,
    count: initialCount,
    incrementCart,
    isListingPage,
    buttonLabel,
    cartLabel,
    isBooking = false,
    showProductOrderForm = true,
    isOwnListing = false,
    updateCartCount,
    openModalOnClick,
  } = props;

  const [localCount, setLocalCount] = useState(initialCount);

  useEffect(() => {
    setLocalCount(initialCount);
  }, [initialCount]);

  if (isBooking || !showProductOrderForm) {
    return null;
  }


  const increaseCount = () => setLocalCount(prevCount => prevCount + 1);
  const decreaseCount = () => setLocalCount(prevCount => prevCount - 1);

  const currentStock = listing?.currentStock?.attributes.quantity;
  const isMaxItems = currentStock <= localCount;

  const handleAddToCart = () => {
    if ((!isOwnListing) && localCount > 0) {
      const incrementValue = localCount - initialCount;
      incrementCart(incrementValue);
      updateCartCount(localCount);
      openModalOnClick();
    }
  };

  return (
    <div className={css.addToCartContainer}>
      <div className={css.quantityCountContainer}>
        <div className={css.cartLabel}>{isListingPage && (cartLabel)}</div>
        <div className={css.buttonContainer}>
          <button className={css.smallButton} onClick={decreaseCount} disabled={localCount <= 0}>
            -
          </button>
          <span className={css.countContainer}>{localCount}</span>
          <button className={css.smallButton} disabled={isMaxItems} onClick={increaseCount}>
            +
          </button>
        </div>
      </div>
      <button className={css.bigButton} onClick={handleAddToCart} disabled={localCount <= 0}>
        {buttonLabel}
      </button>
    </div>
  );
};

AddToCartButton.defaultProps = {
  isListingPage: false,
};

export default AddToCartButton;
